import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, scan, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { differenceBy } from 'lodash';

import { Contact, EntityType, Institution } from '@ipreo/bd-external-participants';

import * as dialogActions from '../actions/dialog-view.action';
import { SubmitLocation } from '../actions/dialog-view.action';
import * as eventActivityActions from '../actions/event-activity.action';
import { UpdateLocation } from '../actions/event-activity.action';
import * as adfActions from '../actions/activity-defined-field.action';
import * as attachmentActions from '../actions/attachment.action';
import * as securitiesActions from '../actions/securities.action';

import * as fromEventActivity from '../selectors/event-activity.selector';
import * as fromErrors from '../selectors/error.selector';
import * as fromDialog from '../selectors/dialog-view.selector';
import * as fromAttachment from '../selectors/attachment.selector';
import * as fromAdf from '../selectors/activity-defined-field.selector';
import * as fromSecurities from '../selectors/securities.selector';
import * as fromStore from '../../store';
import * as fromRoot from '../../../store';

import { CloseDialogAction } from '../../../models';
import {
  ActivityAttachment,
  ActivityDefinedField,
  ActivityLocation,
  ActivitySavedPayload,
  ChangeAction,
  ChangeEntity,
  ChangeField,
  ChangeItem,
  ContactExternalParticipant,
  DialogSaveResult,
  Error,
  EventActivity, EventActivityModel,
  InstitutionExternalParticipant,
  Participant,
  Security
} from '../../models';
import * as fromSelectors from '../selectors';
import { of, throwError } from 'rxjs';
import { MapsService } from '../../services/maps.service';

@Injectable()
export class DialogViewEffects {
  constructor(private actions$: Actions,
              private store: Store<fromStore.State>,
              private mapsService: MapsService) {}

  private lastSaveResult$ = this.actions$.pipe(
    ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS),
    map((action: eventActivityActions.SaveEventActivitySuccess) => action.payload),
    scan<ActivitySavedPayload>((accResult, currentPayload) => ({
      ...currentPayload,
      isParticipantsChanged: (!!accResult && accResult.isParticipantsChanged) || currentPayload.isParticipantsChanged
    }), null)
  );

  @Effect()
  CloseAfterSave$ = this.lastSaveResult$.pipe(
    filter(payload => payload.savingErrors.length === 0),
    map(
      payload =>
        new fromRoot.CloseDialog<DialogSaveResult>(CloseDialogAction.Cancel, {
          activity: payload.hubLoadActivity,
          participantsUpdated: payload.isParticipantsChanged
        })
    )
  );

  @Effect()
  CloseAfterCancel$ = this.actions$.pipe(
    ofType(dialogActions.CANCEL_FORM),
    // tslint:disable-next-line:deprecation
    withLatestFrom(this.lastSaveResult$.pipe(startWith(null))),
    map(
      ([_, lastSaveResult]) =>
        lastSaveResult ?
          new fromRoot.CloseDialog<DialogSaveResult>(CloseDialogAction.Cancel, {
            activity: lastSaveResult.hubLoadActivity,
            participantsUpdated: lastSaveResult.isParticipantsChanged
          }) :
          new fromRoot.CloseDialog()
    )
  );

  @Effect()
  AppendAdfChangeLog$ = this.actions$.pipe(
    ofType(dialogActions.SUBMIT_FORM),
    withLatestFrom(
      this.store.pipe(select(fromEventActivity.getEventActivityIsValid)),
      this.store.pipe(select(fromAdf.getRemovedFields))
    ),
    filter(([, isValid]) => isValid),
    map(
      ([, , fields]) =>
        new dialogActions.UpdateChangeLog(
          fields.map(
            field =>
              <ChangeItem>{ entity: ChangeEntity.Adf, action: ChangeAction.Delete, id: field.tagId }
          )
        )
    )
  );

  @Effect()
  Submit$ = this.actions$.pipe(
    ofType(dialogActions.SUBMIT_FORM),
    withLatestFrom(this.store.pipe(select(fromEventActivity.getEventActivityIsValid))),
    filter(([, isValid]) => isValid),
    withLatestFrom(
      this.store.pipe(select(fromErrors.getEventActivityError)),
      this.store.pipe(select(fromDialog.getChangeLog)),
      this.store.pipe(select(fromEventActivity.getEventActivityEntity)),
      this.store.pipe(select(fromAdf.getSelectedFields)),
      this.store.pipe(select(fromAdf.getRemovedFields)),
      this.store.pipe(select(fromAttachment.getActivityAttachments)),
      this.store.pipe(select(fromSecurities.getSelectedSecurities))
    ),
    map(
      ([, error, changeLog, entity, activityDefinedFields, removedFields, attachments, securities]) =>
        error === Error.PartialSaveError ||
        this.isActivityChanged(changeLog, entity, activityDefinedFields, removedFields, attachments, securities)
          ? new eventActivityActions.SaveEventActivity()
          : new fromRoot.CloseDialog()
    )
  );

  @Effect()
  ResetChangeLog$ = this.actions$.pipe(
    ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS),
    map(() => new dialogActions.ResetChangeLog())
  );

  @Effect()
  ResetDirtyFlag$ = this.actions$.pipe(
    ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS),
    map(() => new dialogActions.ResetDirtyFlag())
  );

  @Effect()
  ResetFailedParticipants$ = this.actions$.pipe(
    ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS),
    withLatestFrom(this.store.pipe(select(fromDialog.getFailedParticipantsInformation))),
    filter(([_, info]) => !!info),
    map(() => new dialogActions.UpdateFailedParticipants(null))
  );

  @Effect()
  TrackLocationUpdate$ = this.actions$.pipe(
    ofType(dialogActions.SUBMIT_LOCATION),
    map((action: dialogActions.SubmitLocation) => action.payload),
    withLatestFrom(
      this.store.pipe(select(fromDialog.getChangeLog)),
      this.store.pipe(select(fromEventActivity.getEventActivityEntity))
    ),
    filter(([location, changeLog, entity]: [ActivityLocation, ChangeItem[], EventActivity]) =>
        this.filterLocationActions(location, changeLog, entity)
    ),
    map(([location, , entity]: [ActivityLocation, ChangeItem[], EventActivity]) => new dialogActions.UpdateChangeLog([
      {
        entity: ChangeEntity.Location,
        action: this.getChangeLocationAction(location),
        id: entity.location && `${entity.location.activityLocationId}`
      }
    ]))
  );

  @Effect()
  TriggerLocationUpdate$ = this.actions$.pipe(
    ofType(dialogActions.SUBMIT_LOCATION),
    map((action: dialogActions.SubmitLocation) => action.payload),
    map((location: ActivityLocation) => new eventActivityActions.UpdateLocation(location))
  );

  @Effect()
  TrackParticipantUpdate$ = this.actions$.pipe(
    ofType<dialogActions.SubmitInternalParticipants>(dialogActions.SUBMIT_INTERNAL_PARTICIPANTS),
    map(action => action.payload),
    withLatestFrom(this.store.pipe(select(fromEventActivity.getEventActivityInternalParticipants))),
    map(([newParticipants, oldParticipants]) =>
      this.trackParticipantChange(
        newParticipants,
        oldParticipants,
        ChangeEntity.InternalParticipant
      )
    ),
    filter(changes => !!changes.length),
    map(changes => new dialogActions.UpdateChangeLog(changes))
  );

  @Effect()
  TriggerParticipantUpdate$ = this.actions$.pipe(
    ofType<dialogActions.SubmitInternalParticipants>(dialogActions.SUBMIT_INTERNAL_PARTICIPANTS),
    map(action => action.payload),
    map(payload => new eventActivityActions.UpdateInternalParticipants(payload))
  );

  @Effect()
  TrackSubjectUpdate$ = this.log(eventActivityActions.UPDATE_SUBJECT);
  @Effect()
  TrackStartDateUpdate$ = this.log(eventActivityActions.UPDATE_START_DATE);
  @Effect()
  TrackDurationUpdate$ = this.log(eventActivityActions.UPDATE_DURATION);
  @Effect()
  TrackEndDateUpdate$ = this.log(eventActivityActions.UPDATE_END_DATE);
  @Effect()
  TrackEventLocationUpdate$ = this.log(eventActivityActions.UPDATE_EVENT_LOCATION);
  @Effect()
  TrackTemplateUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_TEMPLATE);
  @Effect()
  TrackTypeUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_SUB_TYPE);
  @Effect()
  TrackModeUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_MODE);
  @Effect()
  TrackMethodUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_METHOD);
  @Effect()
  TrackInitiatorUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_INITIATOR);
  @Effect()
  TrackNotesUpdate$ = this.log(eventActivityActions.UPDATE_NOTES);
  @Effect()
  TrackTimeZoneUpdate$ = this.log(eventActivityActions.UPDATE_TIMEZONE);
  @Effect()
  TrackOnlineMeetingLinkUpdate$ = this.log(eventActivityActions.UPDATE_ONLINE_MEETING_LINK);
  @Effect()
  TrackOnlineMeetingPasswordUpdate$ = this.log(eventActivityActions.UPDATE_ONLINE_MEETING_PASSWORD);

  @Effect()
  TrackExternalParticipantDelete$ = this.actions$.pipe(
    ofType<eventActivityActions.DeleteExternalParticipant>(
      eventActivityActions.DELETE_EXTERNAL_PARTICIPANT
    ),
    map(action => action.payload),
    filter(d => !!d.id),
    map(
      participant =>
        new dialogActions.UpdateChangeLog([
          {
            entity: ChangeEntity.ExternalParticipant,
            action: ChangeAction.Delete,
            id: participant.id
          }
        ])
    )
  );

  @Effect()
  TrackExternalParticipantUpdateStatus$ = this.actions$.pipe(
    ofType<eventActivityActions.UpdateExternalParticipantStatus>(
      eventActivityActions.UPDATE_EXTERNAL_PARTICIPANT_STATUS
    ),
    map(action => action.payload),
    filter(d => !!d.participant.id),
    map(
      payload =>
        new dialogActions.UpdateChangeLog([
          {
            entity: ChangeEntity.ExternalParticipant,
            action: ChangeAction.Update,
            id: payload.participant.id,
            field: ChangeField.Status
          }
        ])
    )
  );

  @Effect()
  TrackAddressCommentUpdate$ = this.actions$.pipe(
    ofType<eventActivityActions.UpdateAddressComment>(eventActivityActions.UPDATE_ADDRESS_COMMENT),
    map(action => action.payload),
    withLatestFrom(
      this.store.pipe(select(fromEventActivity.getAddressComment)),
      this.store.pipe(select(fromDialog.getChangeLog))
    ),
    filter(([addressComment, oldAddressComment, changeLog]) => addressComment &&
      oldAddressComment &&
      oldAddressComment.tagId &&
      !changeLog.some(d => d.entity === ChangeEntity.AddressComment)),
    map(([, oldAddressComment]) => new dialogActions.UpdateChangeLog(
      [{
        entity: ChangeEntity.AddressComment,
        action: ChangeAction.Update,
        id: oldAddressComment.tagId } as ChangeItem]
    ))
  );

  @Effect()
  UpdateExternalContacts$ = this.actions$.pipe(
    ofType<dialogActions.SubmitExternalParticipants>(dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS),
    map(action => action.payload),
    withLatestFrom(this.store.pipe(select(fromEventActivity.getExternalParticipants))),
    switchMap(([newParticipants, oldParticipants]) => {
      const contacts = newParticipants
        .filter(d => d.type === EntityType.Contact)
        .map((d: Contact) => {
          const participant = oldParticipants.find(
            a =>
              a.contact &&
              ((d.prId && a.contact.id.prId === d.prId) ||
                (d.crmId && a.contact.id.crmId === d.crmId))
          );
          return new ContactExternalParticipant(
            d.prId,
            d.crmId,
            d.name,
            d.accountName,
            d.prCompanyId,
            d.crmAccountId,
            d.firstName,
            d.lastName,
            participant && participant.id,
            d.mobilePhone,
            d.phone,
            d.email,
            d.companyCity,
            d.companyCountry,
            participant && participant.status
          );
        });
      const institutions = newParticipants
        .filter(d => d.type === EntityType.Institution)
        .map((d: Institution) => {
          const participant = oldParticipants.find(
            a => !a.contact && a.company && a.company.id.prId === d.prId
          );
          return new InstitutionExternalParticipant(
            d.prId,
            d.crmId,
            d.name,
            d.hasContacts,
            participant && participant.id,
            d.city,
            d.country
          );
        });

      const combinedParticipants = [...institutions, ...contacts];

      const difference = this.trackParticipantChange(
        combinedParticipants,
        oldParticipants,
        ChangeEntity.ExternalParticipant
      );

      return [
        new eventActivityActions.UpdateExternalParticipants(combinedParticipants),
        new dialogActions.UpdateChangeLog(difference)
      ];
    })
  );

  @Effect()
  TrackAdfUpdate$ = this.actions$.pipe(
    ofType<adfActions.UpdateActivityDefinedField>(adfActions.UPDATE_ACTIVITY_DEFINED_FIELD),
    map(action => action.payload.field),
    withLatestFrom(
      this.store.pipe(select(fromDialog.getChangeLog)),
      this.store.pipe(select(fromEventActivity.getEventActivityEntity))
    ),
    filter(
      ([field, changeLog, entity]) =>
        !!entity.activityId &&
        !!field.tagId &&
        field.values.length > 0 &&
        !changeLog.find((d: ChangeItem) => d.entity === ChangeEntity.Adf && d.id === field.tagId)
    ),
    map(([field]) => {
      return new dialogActions.UpdateChangeLog([
        {
          entity: ChangeEntity.Adf,
          action: ChangeAction.Update,
          id: field.tagId
        }
      ]);
    })
  );

  @Effect()
  MarkFormDirty$ = this.actions$.pipe(
    ofType(
      eventActivityActions.UPDATE_START_DATE,
      eventActivityActions.UPDATE_END_DATE,
      eventActivityActions.UPDATE_DURATION,
      eventActivityActions.UPDATE_EXTERNAL_PARTICIPANT_STATUS,
      eventActivityActions.CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS,
      eventActivityActions.DELETE_EXTERNAL_PARTICIPANT,
      dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS,
      dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST,
      dialogActions.SUBMIT_INTERNAL_PARTICIPANTS,
      eventActivityActions.UPDATE_ACTIVITY_TEMPLATE,
      eventActivityActions.UPDATE_ACTIVITY_INITIATOR,
      eventActivityActions.UPDATE_SUBJECT,
      eventActivityActions.UPDATE_LOCATION,
      eventActivityActions.UPDATE_EVENT_LOCATION,
      eventActivityActions.UPDATE_NOTES,
      adfActions.UPDATE_ACTIVITY_DEFINED_FIELD,
      adfActions.HIDE_ACTIVITY_DEFINED_FIELD,
      attachmentActions.ADD_ACTIVITY_ATTACHMENTS,
      attachmentActions.REMOVE_ACTIVITY_ATTACHMENT,
      securitiesActions.SET_SELECTED_SECURITIES,
      eventActivityActions.UPDATE_ADDRESS_COMMENT,
      eventActivityActions.UPDATE_ONLINE_MEETING_LINK,
      eventActivityActions.UPDATE_ONLINE_MEETING_PASSWORD
    ),
    map(action => new dialogActions.MarkFormDirty())
  );

  @Effect()
  TriggerTimeZoneUpdate$ = this.actions$.pipe(
    ofType(eventActivityActions.UPDATE_LOCATION),
    map((action: UpdateLocation) => action.payload),
    withLatestFrom(this.store.pipe(select(fromSelectors.getIsEventActivity))),
    filter(([location, eventActivity]) => !eventActivity && !!location && !!location.googlePlaceId),
    map(([location]) => new dialogActions.SubmitTimeZoneUpdate(location.googlePlaceId))
  );

  @Effect()
  SetDefaultTimeZone$ = this.actions$.pipe(
    ofType(dialogActions.SUBMIT_LOCATION),
    map((action: SubmitLocation) => action.payload),
    withLatestFrom(
      this.store.pipe(select(fromSelectors.getIsEventActivity)),
      this.store.pipe(select(fromSelectors.getEventActivityLocation)),
      this.store.pipe(select(fromRoot.getTimeZone))
    ),
    filter(([location, isEventActivity, oldLocation]) => !isEventActivity && !!oldLocation && !location),
    map(([, , , defaultTimeZone]) => new eventActivityActions.UpdateTimeZone(defaultTimeZone))
  );

  @Effect()
  UpdateTimeZone$ = this.actions$.pipe(
    ofType(dialogActions.SUBMIT_TIME_ZONE_UPDATE),
    map((action: dialogActions.SubmitTimeZoneUpdate) => action.payload),
    switchMap(googlePlaceId => {
      return this.mapsService.getTimeZone(googlePlaceId).pipe(
        switchMap(timeZone => timeZone ? [
          new eventActivityActions.UpdateTimeZone(timeZone),
          new dialogActions.UpdateTimeZoneSuccess()
        ] : throwError(null)),
        catchError(() => of(
          new dialogActions.UpdateTimeZoneFail()
        ))
      );
    })
  );

  @Effect()
  TimeZoneFailed$ = this.actions$.pipe(
    ofType(dialogActions.UPDATE_TIME_ZONE_FAIL),
    withLatestFrom(this.store.pipe(select(fromRoot.getTimeZone))),
    switchMap(([, timeZone]) => [
      new eventActivityActions.UpdateLocation(null),
      new eventActivityActions.UpdateTimeZone(timeZone)
    ])
  );

  @Effect()
  SetPrototypeWarnings$ = this.actions$.pipe(
    ofType<eventActivityActions.LoadEventActivityModelSuccess>(eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS),
    map(action => action.payload),
    map((model: EventActivityModel) => new dialogActions.SetPreselectWarnings(model.activityPrototypeWarnings))
  );

  private trackParticipantChange(
    newParticipants: Participant[],
    oldParticipants: Participant[],
    entity: ChangeEntity
  ) {
    const newParticipantsWithId = newParticipants.filter(d => d.id);
    const oldParticipantsWithId = oldParticipants.filter(d => d.id);

    return differenceBy(
      oldParticipantsWithId,
      newParticipantsWithId,
      d =>
        (d.contact && (d.contact.id.prId || d.contact.id.crmId)) ||
        (!d.contact && d.company && (d.company.id.prId || d.company.id.crmId))
    ).map(difference => ({
      entity: entity,
      action: ChangeAction.Delete,
      id: difference.id
    }));
  }

  private filterAddActivityActions(changeLog: ChangeItem[], entity: EventActivity) {
    return !!entity.activityId && !changeLog.find(d => d.entity === ChangeEntity.Activity);
  }

  private filterLocationActions(location: ActivityLocation, changeLog: ChangeItem[], entity: EventActivity) {
    return (
      !!entity.activityId &&
      !!entity.location &&
      !!entity.location.activityLocationId &&
      !changeLog.find(
        d => d.entity === ChangeEntity.Location && d.action === this.getChangeLocationAction(location)
      )
    );
  }

  private log(action: string) {
    return this.actions$.pipe(
      ofType(action),
      withLatestFrom(
        this.store.pipe(select(fromDialog.getChangeLog)),
        this.store.pipe(select(fromEventActivity.getEventActivityEntity))
      ),
      filter(([, changeLog, entity]) => this.filterAddActivityActions(changeLog, entity)),
      map(
        () =>
          new dialogActions.UpdateChangeLog([
            {
              entity: ChangeEntity.Activity,
              action: ChangeAction.Update
            }
          ])
      )
    );
  }

  private getChangeLocationAction(location: ActivityLocation) {
    if (!location) {
      return ChangeAction.Delete;
    }
    return ChangeAction.Update;
  }

  private isActivityChanged(
    changeLog: ChangeItem[],
    entity: EventActivity,
    activityDefinedFields: ActivityDefinedField[],
    removedFields: ActivityDefinedField[],
    attachments: ActivityAttachment[],
    securities: Security[]
  ): boolean {
    if (changeLog.length > 0) {
      return true;
    }

    if (!entity.activityId) {
      return true;
    }

    if (entity.location && !entity.location.activityLocationId) {
      return true;
    }

    if (entity.internalParticipants && !!entity.internalParticipants.find(p => !p.id)) {
      return true;
    }

    if (entity.externalParticipants && !!entity.externalParticipants.find(p => !p.id)) {
      return true;
    }

    if (removedFields.length > 0) {
      return true;
    }

    if (
      activityDefinedFields &&
      !!activityDefinedFields.find(adf => !adf.tagId && adf.values.length > 0)
    ) {
      return true;
    }

    if (!!attachments && attachments.filter(a => !a.id).length > 0) {
      return true;
    }

    if (
      securities &&
      !!securities.find(s => !s.tagId)
    ) {
      return true;
    }

    if (
      entity.addressComment && (
        entity.addressComment.tagId &&
        !entity.addressComment.value ||
        !entity.addressComment.tagId &&
        entity.addressComment.value
      )
    ) {
      return true;
    }

    return false;
  }
}
