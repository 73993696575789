import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { withLatestFrom, switchMap, map, tap, filter} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import * as kendoFileSaver from 'file-saver';
import { ChangeEntity, ChangeAction, ActivityAttachment, ActivityDocumentDto } from '../../models';
import * as activityAttachmentAction from '../actions/attachment.action';
import * as dialogAction from '../actions/dialog-view.action';
import * as fromRoot from '..';
import { getActivityAttachments } from '../selectors';
import { getActivityId } from 'src/app/store';
import { ActivityAttachmentService } from '../../services';
import * as eventActivityAction from '../actions/event-activity.action';
import { LoadActivityAttachments } from '../actions/attachment.action';
import { ActivityAttachmentMapper } from '../../services/activity-attachment-mapper.service';

@Injectable()
export class AttachmentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private attachmentService: ActivityAttachmentService,
    private attachmentMapper: ActivityAttachmentMapper
  ) {}

  @Effect()
  LoadAttachments$ = this.actions$.pipe(
    ofType<eventActivityAction.LoadEventActivityModelSuccess>(
      eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS
    ),
    filter(action => !!action.payload.attachments && action.payload.attachments.length > 0),
    map(action => action.payload.attachments),
    this.mapAttachments()
    );

  @Effect()
  SetAttachmentsAfterSave$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS),
    map((action: eventActivityAction.SaveEventActivitySuccess) => action.payload.activity.attachments),
    this.mapAttachments()
  );

  @Effect()
  TrackAttachmentDelete$ = this.actions$.pipe(
    ofType<activityAttachmentAction.RemoveActivityAttachmentSubmit>(
      activityAttachmentAction.REMOVE_ACTIVITY_ATTACHMENT_SUBMIT
    ),
    withLatestFrom(this.store.pipe(select(getActivityAttachments))),
    switchMap(
      ([action, attachments]: [activityAttachmentAction.RemoveActivityAttachmentSubmit, ActivityAttachment[]]) => {
      const removedDocId = attachments.find(att => action.uId === att.uid).id;
      return [
          (!!removedDocId) &&
            new dialogAction.UpdateChangeLog([{
                entity: ChangeEntity.Attachment,
                action: ChangeAction.Delete,
                id: removedDocId
              }]),
            new activityAttachmentAction.RemoveActivityAttachment(action.uId)
        ].filter(Boolean);
      }
    )
  );

  @Effect({ dispatch: false })
  downloadSavedActivityAttachment$ = this.actions$.pipe(
    ofType(activityAttachmentAction.DOWNLOAD_ACTIVITY_ATTACHMENT),
    map((action: activityAttachmentAction.DownloadActivityAttachment) => action.attachment),
    filter((attachment: ActivityAttachment) => !attachment.rawFile),
    withLatestFrom(this.store.pipe(select(getActivityId))),
    switchMap(([attachment, activityId]: [ActivityAttachment, string]) => {
      return this.attachmentService.getAttachment({activityId, documentId: attachment.id}).pipe(
        map(blob => ({
          blob,
          name: attachment.name
        }))
      );
    }),
    tap((payload) => {
      kendoFileSaver.saveAs(payload.blob, payload.name);
    }));

  @Effect({ dispatch: false })
  downloadUnsavedActivityAttachment$ = this.actions$.pipe(
    ofType(activityAttachmentAction.DOWNLOAD_ACTIVITY_ATTACHMENT),
    map((action: activityAttachmentAction.DownloadActivityAttachment) => action.attachment),
    filter((attachment: ActivityAttachment) => !!attachment.rawFile),
    tap((attachment: ActivityAttachment) =>
      kendoFileSaver.saveAs(attachment.rawFile, attachment.name)
    ));

  @Effect()
  ClearErrors$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY),
    map(() => new activityAttachmentAction.SetErrors([]))
  );

  private mapAttachments() {
    return pipe(
      map((attachments: ActivityDocumentDto[]) => (
        new LoadActivityAttachments(attachments.map(attachment => this.attachmentMapper.fromDto(attachment)))))
    );
  }
}
